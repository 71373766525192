.WishlistHowTo {
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s opacity;

  &--inactive {
    pointer-events: none;
    opacity: 0;
  }

  &_popup {
    background-color: #fff;
    width: 60vw;
    max-width: 1200px;
    height: 35.25vw;
    max-height: 67.5rem;
    padding: 6.5rem 10rem 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 47.5rem;

    @media screen and (max-width: 1875px) {
      padding: 5rem 8rem 7rem;
    }

    @media screen and (max-width: 1400px) {
      padding: 3rem 5.75rem 4.3rem;
      width: 70vw;
    }

    @media screen and (max-width: 1080px) {
      padding: 2rem 4.4rem 3.2rem;
      width: 85.75vw;
      min-height: 43rem;
    }

    @media screen and (max-width: 820px) {
      min-height: none;
      padding: 3rem 1rem 5rem;
      width: 70vw;
      height: 90vh;

      #howToTitle {
        display: block;
        width: 10rem;
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 550px) {
      width: 90vw;
      height: 90vh;
    }

    @media screen and (max-width: 450px) {
      padding: 5rem 1rem 6.7rem;
      width: 100vw;
      height: 100vh;
    }


  }

  &_title {
    font-size: 4.2rem;
    text-transform: initial;
    text-align: center;
    overflow-y: visible !important;

    span {
      text-transform: initial;
    }

    @media screen and (max-width: 1870px) {
      font-size: 3.2rem;
    }

  }

  &_guide {
    max-height: 60%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 820px) {
      flex-direction: column;
      max-height: none;


    }

    &--step {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .icon--container {
        margin: 0.4rem 0 4.3rem;
        position: relative;
        top: 25%;
        transform: translate(0,-50%);
        width: 100%;
        height: auto;
        @media screen and (max-width: 820px) {
          padding-right: 1rem;
          max-height: 100%;
          width: 17.5rem;
          top: 0;
          transform: translate(0,0);
          margin: .5rem;
          display: flex;
          justify-content: right;
          svg {
            max-height: 100%;
            max-width: none;
            width: 7rem;
            margin: 0 0 0 auto
          }
        }
        svg {
          height: 100%;
          max-width: 100%;
        }
      }

      &_1 { 
        width: 11rem;
        @media screen and (max-width: 1600px) {
          width: calc(11rem * .9);
        }
        @media screen and (max-width: 1150px) {
          width: calc(11rem * .85);
        }
      }
      &_2 { 
        width: 12rem;
        @media screen and (max-width: 1600px) {
          width: calc(12rem * .9);
        }
        @media screen and (max-width: 1150px) {
          width: calc(12rem * .85);
        }
       }
      &_3 { 
        width: 15rem;
        @media screen and (max-width: 1600px) {
          width: calc(15rem * .9);
        }
        @media screen and (max-width: 1150px) {
          width: calc(15rem * .85);
        }
      }
      &_4 {
        width: 12.6rem;
        @media screen and (max-width: 1600px) {
          width: calc(12.6rem * .9);
        }
        @media screen and (max-width: 1150px) {
          width: calc(12.6rem * .85);
        }

      }

      @media screen and (max-width: 820px) {
        max-width: none;
        width: 70%;
        justify-content: center;
        flex-direction: row; align-items: center;
        height: 25%;
        min-height: 7.1rem;
        margin: 1rem;

      }

      @media screen and (max-width: 550px) {
        width: 95%;
      }

      &_text {
        text-align: center;
        font-size: 2.1rem;
        text-transform: capitalize;
        margin-top: -2.25rem;
        @media screen and (max-width: 1875px) {
          font-size: 1.75rem;
        }

        @media screen and (max-width: 1380px) {
          margin-top: -3.9rem;
        }

        @media screen and (max-width: 820px) {
          width: 17.5rem;
          margin-top: 0;
          padding-left: 1rem;
          text-align: left;
        }
      }
    }
    &--arrow {
      width: 1.5rem;
      position: relative;
      bottom: 25%;
      transform: translateY(40%);

      @media screen and (max-width: 820px) {
        display: none;
      }

      svg {
        width: 100%;
      }
    }
  }

  &_button {

    @include submit(3.5rem);
    width: 28.5rem;

    @media screen and (max-width: 1875px) {
      font-size: 2.1rem;
      height: 5.8rem;
      width: 23.25rem;
    }
  }
}
