.header {
  position: relative;
  width: 100%;
  height: 45vh;
  max-height: 70rem;
  min-height: 20vmax;
  background-size: cover;
  background-position: bottom center;

  @media screen and (max-width: 1300px) {
    height: 55vh;
  }

  @media screen and (max-width: 560px) {
    height: 50vh;
    min-height: 35rem;
    max-height: 47.5rem;
  }

  .brand-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;

    @media screen and (max-width: 1300px) {
      max-height: 80%;
      top: 57.5%;
    }

    @media only screen and (max-width: 650px) {
      max-width: 66vw;
      max-height: 73%;
    }
  }

  .brand-indicator {
    position: absolute;
    top: 3rem; left: 3rem;
    p {
      color: #fff;
      font-size: 1.4rem;
      font-family: "UniversNormal";
      font-weight: 400;
    }

    &_name {
      color: #fff;
      font-size: 3rem;
      margin-top: 1vmin;
    }

    @media only screen and (max-width: 1200px) {
      top: 2.25rem; left: 2.25rem;

      p {
        font-size: 1.05rem;
      }

      &_name {
        font-size: 2.25rem;
      }
    }

    @media only screen and (max-width: 560px) {
      top: 1.69rem;
      left: 1.69rem;
      p {
        font-size: .79rem;
      }

      &_name {
        font-size: 1.69rem;
      }
    }
  }

  .sportex-indicator {
    fill: #fff;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    padding: 2rem 2rem 1.11rem;
    height: 11rem;
    width: 23rem;

    @media only screen and (max-width: 1200px) {
      height: 8.25rem;
      width: 17.25rem;
      padding: 1.5rem 1.5rem .825rem;
    }

    @media only screen and (max-width: 560px) {
      height: 6.2rem;
      width: 12.9rem;
      padding: 1.125rem 1.125rem .62rem;
    }

    &_text {
      position: relative;
      padding-bottom: 0.5rem;
      margin-top: -1.78rem;
      font-size: 1rem;

      @media only screen and (max-width: 1200px) {
        padding-bottom: .175rem;
        margin-top: -1.335rem;
        font-size: .75rem;
      }

      @media only screen and (max-width: 560px) {
        padding-bottom: .13rem;
        margin-top: -1rem;
        font-size: .5625rem;
      }
    }

    &-logo {
      height: auto;
      width: 65%;
    }
  }
}
