.footer {
  background-color: $color-black;
  position: relative;
  height: 20rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  > * {
    color: #efefef;
  }

  &_link {
    display: flex;
    width: 30rem;
    justify-content: space-evenly;
    margin-left: 4.4rem;
    transform: translateY(-62.5%);

    a {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: #efefef;
      text-decoration: none;
      font-size: 1.7rem;

      span {
        margin-right: 0.7vmax;
        svg {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      width: 22.5rem;
      margin-left: 3.7rem;

      a {
        font-size: 1.4rem;
      }
    }

    &-email {
      &_icon {
        width: 3rem;

        @media screen and (max-width: 1200px) {
          width: 2.25rem;
        }
      }
    }
    &-site {
      &_icon {
        width: 2rem;
        transform: translateY(-0.3vmax);

        @media screen and (max-width: 1200px) {
          width: 1.5rem;
        }
      }
    }
  }

  &_cta {
    text-align: center;

    &_title {
      font-size: 4rem;
      line-height: 0.83;

      @media screen and (max-width: 1200px) {
        font-size: 3rem;
      }
    }

    &_subtitle {
      font-size: 2.2rem;
      @media screen and (max-width: 1200px) {
        font-size: 1.65rem;
      }
    }

    &_link {
      // color: #d0d43e;
      text-decoration: none;
    }
  }

  &_sportex-indicator {
    justify-self: right;
    margin-right: 6.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 6.4vw;
    width: 17rem;

    @media screen and (max-width: 1200px) {
      width: 12.6rem;
    }
    &-logo {
      fill: #fff;
      height: auto;
      width: 100%;
    }

    &_text {
      display: inline-block;
      position: relative;
      padding-bottom: 0.42vw;
      margin-top: -0.89vw;
      font-size: 12px;

      @media screen and (max-width: 1200px) {
        font-size: 0.8rem;
      }
    }
  }

  &_disclosure {
    position: absolute;
    display: inline-block;
    right: 6.2rem;
    bottom: 1.6rem;
    opacity: 0.5;
    font-size: 0.9rem;
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: repear(3, 1fr);
    height: 40rem;

    &_link {
      margin: 0 auto;
      padding: 3rem 3rem 3.5rem;
      width: 100%;

      a {
        font-size: 1.5rem;

        p {
          margin-left: .5rem;
        }
      }

      &-email {
        &_icon {
          width: 3.2rem;
          transform: translateY(.2rem);
        }
      }
      &-site {
        &_icon {
          width: 1.9rem;
          transform: translateY(-.2rem);
        }
      }
    }

    &_cta {
      order: -1;
      padding: 6rem 3rem 7rem;

      &_title {
        font-size: 4.2rem;
        line-height: .9;
      }

      &_subtitle {
        font-size: 2.1rem;
      }
    }

    &_sportex-indicator {
      justify-self: center;
      width: 32rem;
      margin: -4rem 0 6rem;
      min-height: 7rem;

      &-logo {
        width: 20rem;
        height: auto;
      }

      &_text {
        font-size: 1rem;
        padding-bottom: 1.5rem;
      }
    }

    &_disclosure {
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      text-align: center;
      padding-bottom: .5rem;
    }
  }
}
