html {
  scroll-behavior: smooth;
  font-size: 10px;
  overflow-x: hidden;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,span,h1,h2,h3,h4,h5,h6,button,a,li {
  font-family: 'UniversCondensed';
  font-weight: 800;
  text-transform: uppercase;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

input::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.App {
  background-color: #e4e4e4;
}

.activeInput {
  label {
    transform: translate(0, -500%) !important;
  }

  &::before {
    width: 100%;
  }
}

.icon {
  &-note {
    stroke: green !important;

    > * {
      stroke: green !important;
    }
  }
}

.cta {
  &-addToList {
    position: relative;
    &--icon {
      width: 12.5%;
      max-width: 2.4rem;
      height: auto;
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translate(0%, -45%);
      transition: 0.225s transform ease-out, 0.18s opacity ease;

      @media screen and (max-width: 1650px) {
        max-width: 2.1rem;
      }

      svg {
        max-width: 100%;
      }

      &-2 {
        opacity: 0;
      }
    }
    &:hover {
      // &-2
      div {
        opacity: 1;
      }
    }
  }

  &-zoomIn {
    position: relative;

    &--icon {
      width: 15%;
      max-width: 2.1rem;
      height: auto;
      position: absolute;
      right: 7.5%;
      top: 50%;
      transform: translate(0%, -45%);

      svg {
        max-width: 100%;

        g > * {
          stroke: #fff;
          transition: 0.15s stroke ease-in-out;
        }
      }

      &-2 {
        position: absolute;
        z-index: 100;
      }
    }

    span {
      color: #fff;
      transition: 0.15s color ease-in-out;
    }

    &:hover {
      span {
        color: #000;
      }
      .cta-zoomIn--icon {
        svg g > * {
          stroke: #000;
        }
      }
    }
  }
}

.multi--addItem {
  background-color: $color-black;
  position: relative;
  overflow: hidden;
  
  svg {
    position: absolute;
    transition: 0.05s opacity ease-out;
    z-index: 500;
    right: 1.5rem;
  }

  &__1 {
    opacity: 1;
   }

  &__2 {
    opacity: 0;
  }

  // &-check {
  //   transform: translate(0,0);
  // }

  &:hover {
    .multi--addItem__1 {
      opacity: 0;
    }

    .multi--addItem__2 {
      opacity: 1;
    }
  }
}

.company-input {
  margin: 0.5rem 0.5rem 0.5rem 0;
}
.phone-input {
  margin: 0.5rem 0 0.5rem 0.5rem;
}

@media screen and (max-width: 725px) {
  .company-input,
  .phone-input {
    margin: 0.5rem 0;
  }
}

.added-btn {
  padding-right: 5rem;
  padding-left: 1.5rem;
  font-size: 1.8rem;
}

.underline {
  border-bottom: solid 1px #000;
}

#loadingbar {
  position: relative;
  transform-origin: center center;

  width: 10rem;
  height: auto;
  animation: rotate 2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 1s ease-in-out infinite;
    stroke-linecap: round;

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }
    
    @keyframes color {
      100%,
      0% {
        stroke: #D7DE3B;
      }

      80%,
      90% {
        stroke: #e9e9e9;
      }
    }
  }

  &-background {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: $color-black;
    display: flex;
    justify-content: center; align-items: center;
  }
}

#zoomIcon,
#cartCount {
  overflow: visible;
}

.nav-link {
  color: inherit;
  text-decoration: none;
}

.invisible {
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-out;

}

.visible {
  opacity: 1;
  pointer-events: painted;
  transition: opacity .2s ease-out;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}