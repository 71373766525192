.showcase_grid {
  display: grid;
  max-width: 1800px;
  margin: 0 auto;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
  grid-template-columns: minmax(0%, 1fr) minmax(0%, 1fr) minmax(0%, 1fr);
  gap: 0.2rem;
  background-color: #e4e4e4;
  position: relative;

  @media screen and (max-width: 770px) {
    grid-template-columns: minmax(0%, 1fr) minmax(0%, 1fr);
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: minmax(0%, 1fr);
    padding-bottom: 5rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 5rem;
      width: 100%;
      background-color: #fff;
      border-bottom: .4rem solid #e4e4e4;
    }
  }

  &_item {
    background-color: #fff;
    height: 30vmax;
    max-height: 475px;
    padding: 7.5rem 6.52rem 3.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    #multiIcon {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      height: 2.525rem;
    }

    // MOBILE TILE ITEM HEIGHT

    @media screen and (max-width: 361px) {
      padding: 1.25rem 1.5rem;
    }

    @media screen and (min-width: 560px) {
      &:hover {
        > div {
          //opacity: 1;
          height: 50%;
        }
      }
    }

    @media screen and (max-width:1300px){
      &:hover{
        > div{
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 5.5rem 3.52rem 3.5rem;
    }

    @media screen and (max-width: 560px) {
      height: 88vw;
      width: 100vw;
      padding: 4.75rem 5rem 1.25rem;

      .icon-wrapper {
        position: absolute;
        bottom: 0; right: .1rem;
        background-color: $color-black;
        width: 6rem; height: 6rem;
        #multiIcon {
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      > div:not(.showcase_grid_item-cta-multi) {
        display: none;
      }

      &:hover {
        .showcase_grid_item-cta-multi {
          opacity: 1 !important;
        }
      }
    }

    &-cta {
      background-color: rgba(0, 0, 0, 0.85);
      position: absolute;
      z-index: 110;
      width: 100%;
      height: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //opacity: 0;
      transition: 0.2s all linear;
      bottom: 0;
      overflow: hidden;
      &-multi {
        //opacity: 0;
      }

      &_title {
        color: #fff;
        font-size: 36px;
        text-align: center;
        padding: 20px;  
        @media screen and (max-width: 1400px) {
          font-size: 32px;
        }
        @media screen and (max-width: 1200px) {
          font-size: 26px;
          position: relative;
          transform: translateY(2rem);
        }
      }

      &_btn {
        font-size: 2.4rem;
        width: 50%;
        max-width: 25rem;
        height: 6rem;
        margin: 0 0.75rem;
        border: none;
        cursor: pointer;
        flex-grow: 1;
        &--container {
          margin-top: 2rem;
          display: flex;
          width: 80%;
          justify-content: space-evenly;
          max-width: 600px;

          .cta-zoomIn {
            padding-right: 3.5rem;
            padding-left: 2.5rem;
          }

          @media screen and (max-width: 560px) {
            width: 55%;
            .cta-zoomIn {
              display: none;
            }
          }
        }
        @media screen and (max-width: 1550px) {
          font-size: 1.8rem;
          height: 5.5rem;
        }

        @media screen and (max-width: 1200px) {
          margin: 0.5rem 0.75rem;
          height: 5rem;
          width: 77.5%;
          &--container {
            position: relative;
            transform: translateY(2rem);
            flex-direction: column;
            align-items: center;
            width: 100%;
          }
        }

        @media screen and (max-width: 560px) {
          padding: 1.4vmax 1.5vmax;
          font-size: 17px;
        }

        &:active,
        &:focus {
          outline: none;
        }
      }
    }
    &--colspread_1 {
      grid-column-end: span 1;

      @media screen and (max-width: 560px) {
        grid-column-end: 1;
      }
    }

    &--colspread_2 {
      grid-column-end: span 2;
      img {
        width: 80%;
      }
      @media screen and (max-width: 560px) {
        grid-column-end: 1;

        img { width: 105%; max-width: 125%;}
      }
    }

    &--colspread_3 {
      grid-column-end: span 3;

      @media screen and (max-width: 560px) {
        grid-column-end: 1;
      }
    }

    &_image {
      max-width: 100%; max-height: 100%;
      position: relative;
      @media screen and (max-width: 560px) {
        max-width: 95%;
      }
    }

    &-name {
      position: absolute;
      z-index: 100;
      top: 3rem;
      left: 4rem;
      font-size: 1.8rem;
      padding-right: 1rem;
      color: #aaaaaa;

      @media screen and (max-width: 800px) {
        font-size: 16px;
        top: 2rem;
        left: 2rem;
      }
    }
  }

  .zoomModal {
    position: fixed;
    z-index: 150;
    opacity: 0;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 1580px;
    height: 90%;
    background-color: #ffffff;
    padding: 3.25rem 3.75rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2rem 40vw rgba(0, 0, 0, 0);

    transition: 0.3s transform cubic-bezier(0.5, 0.39, 0.05, 1), 0.4s opacity ease-out, 0.275s box-shadow linear;

    &--active {
      opacity: 1;
      pointer-events: painted;
      box-shadow: 0 0 2rem 40vw rgba(0, 0, 0, 0.8);
      transform: translate(-50%, -50%);

      transition: 0.5s transform cubic-bezier(0.5, 0.39, 0.05, 1), 0.3s 0.3s opacity ease-out,
        0.4s 0.2s box-shadow linear;
    }

    &--multi {
      min-height: 100%;
      height: auto;
      transform: translate(-50%, -50vh);
      height: calc(100vh);
      padding: 0;
      width: 1400px;
      z-index: 900;

      @media screen and (max-width: 1475px) {
        width: 900px;

        .zoomModal_name {
          padding-left: 9.5%;
          
        }
        .zoomModal-close {
          width: 2.5rem;
          height: 2.5rem;
        }
        .zoomModal-back {
          width: 4.5rem;
          height: 4.25rem;
        }
        .zoomModal-multi-grid {
          grid-auto-rows: 35rem;
          grid-template-columns: minmax(0%, 1fr) minmax(0%, 1fr);
          width: 80%;
          &-item {
            &:nth-child(even) {
              border-width: 2px 2px 0 0;
            }
            &:nth-child(odd) {
              border-width: 2px 2px 0 2px;
            }

            &:nth-last-child(2),
            &:last-child {
              border-bottom-width: 2px;
            }
          }
        }
      }

      @media screen and (max-width: 1050px) {
        width: 700px;
        .zoomModal-close {
          width: 2.25rem;
          height: 2.25rem;
          right: 2.75rem;
        }
        .zoomModal-back {
          width: 4.2rem;
          height: 3.25rem;
          
          left: 1rem;
        }
        .zoomModal_name {
          font-size: 3.1rem;
        }
        .zoomModal-multi-grid {
          width: 79%;
          grid-auto-rows: 30rem;
          padding: 0;

          &-item {
            &_title {
              font-size: 1.4rem;
            }
          }
        }
      }

      @media screen and (max-width: 750px) {
        max-width: 100%;
        width: 460px;

        .zoomModal_name {
          margin-top: 0;
          position: relative;
          background-color: #fdfdfd;
        }

        .wrapper {
          position: absolute;
          bottom: 1rem;
          width: 100%;
          z-index: 8500;
          height:7.5rem;
          display: flex;
        }
        .zoomModal-close {
          left: 5rem;
          margin-right: 1.75rem;
          position: sticky;
          overflow: visible;
          transform: translate(-50%,-50%);

          svg g line {
            stroke: #fff !important;
          }
          &::before {
            content: "";
            position: absolute;
            width: 6rem;
            height: 6rem;
            box-shadow: 0 .125rem 1.75rem rgba(black, 0.22);
            // background-color: #CD2730;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 0;
          }
        }
        .zoomModal-back {
          left: 0rem;
          top: 0rem;
          margin-right: 1.75rem;
          position: sticky;
          overflow: visible;
          transform: translate(-50%,-50%);

          svg g line {
            stroke: #fff !important;
          }
          //&::before {   
            //content: "";
            //position: absolute;
            //width: 6rem;
            //height: 6rem;
            //box-shadow: 0 .125rem 1.75rem rgba(black, 0.22);
            // background-color: #CD2730;
            //z-index: -1;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);
            //border-radius: 0;
          //}
        }
        .zoomModal-multi-grid {
          width: 100%;
          grid-auto-rows: 35rem;
          grid-template-columns: minmax(0%, 1fr);
          padding: 0 0 0 1.6rem;
          margin-top: 9rem;

          &-item {
            &:nth-child(even) {
              border-width: 2px 2px 0 2px;
            }
            &:nth-child(odd) {
              border-width: 2px 2px 0 2px;
            }
            &:last-child {
              border-bottom-width: 2px;
            }

            &_title {
              font-size: 1.6rem;
            }
          }
        }
      }

      @media screen and (max-width: 560px) {
        .zoomModal-multi-grid {
          grid-auto-rows: 31rem;
          overflow-x: hidden;
        }
        .zoomModal-close {
          width: 2.3rem;
          height: 2.3rem;
        }
        //.zoomModal-back {
          //width: 2.3rem;
          //height: 2.3rem;
        //}
      }

      h2 {
        margin-top: 3rem;
      }
    }

    &_name {
      font-size: 3.6rem;
      padding-bottom: 1rem;
      padding-left: 6.5%;
      z-index: 8000;
      position: relative;
      @media screen and (max-width: 750px) {
        background-color: #fafafa;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        box-shadow: 0 -0.5rem 1.25rem rgba(0, 0, 0, 0.3);
      }

      @media screen and (max-width: 560px) {
        font-size: 2.6rem;
      }
    }

    &_img {
      max-width: 133rem;
      height: auto;

      &--container {
        margin: 3rem auto;
        width: 100%;
        height: 100%;
        min-height: 60rem;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 100%;
        }
      }
    }

    &-addToList {
      height: 4vmax;
      max-height: 5.8rem;
      min-height: 5.5rem;
      width: 32%;
      max-width: 23rem;

      margin: 0rem auto 4rem;
      // display: inherit;
    }

    &-close {
      position: fixed;
      width: 3rem;
      height: 3rem;
      right: 3.25rem;
      top: 3.75rem;
      cursor: pointer;
      z-index: 9000;
      > svg * {
        stroke-width: 4px;
      }
    }

    &-back {
      position: fixed;
      width: 5rem;
      height: 4rem;
      left: 3.25rem;
      top: 3.05rem;
      cursor: pointer;
      z-index: 9000;
      > svg * {
        stroke-width: 4px;
      }
    }

    &-multi-grid {
      max-width: 86%;
      margin: 1.5rem auto 5vmax;
      display: grid;
      min-height: 50rem;
      grid-auto-rows: 40rem;
      grid-auto-flow: dense;
      grid-template-columns: minmax(0%, 1fr) minmax(0%, 1fr) minmax(0%, 1fr);
      align-items: stretch;
      transform: translateX(-0.85rem);

      &-item {
        position: relative;
        background-color: #ffffff;
        padding: 2.25rem;
        font-size: 1.8rem;
        border: 2px solid #e3e3e3;
        align-self: stretch;

        &:nth-child(3n + 1) {
          border-right-width: 2px;
          border-bottom-width: 2px;
        }

        &:nth-child(3n + 2) {
          border-right-width: 2px;
          border-left-width: 0;
          border-bottom-width: 2px;
        }

        &:nth-child(3n + 0) {
          border-right-width: 2px;
          border-left-width: 0;
        }

        &:nth-child(n + 4) {
          border-top-width: 0;
        }

        &_title {
          opacity: 0.5;
        }

        .img-container {
          width: 100%;
          height: 95%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          img {
            max-height: 100%; max-width: 100%;
          }

          @media screen and (max-width: 460px) {
            min-width: 18rem;
          }
        }
        .multi--addItem {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 2.8vmax;
          transform: translateY(0.125rem);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          svg {
            width: 2.85rem;
            height: auto;
            pointer-events: none;
          }
        }
      }
    }
  }
}

#singleModal {
  overflow-y: visible;
  max-height: 87.5rem;
  z-index: 800;
  @media screen and (min-width: 1800px) {
    overflow-y: auto;
  }

  @media screen and (max-width: 1650px) {
    width: 85%;
    max-height: 100vh;
    min-height: 65vh;
    height: 50vw;
    padding: 2.5rem 2.25rem 1rem;

    h2 {
      font-size: 2.8rem;
      margin-bottom: 5rem;
    }

    .zoomModal-close {
      width: 2.5rem;
      height: 2.5rem;
    }
    .zoomModal-back {
      width: 2.5rem;
      height: 2.5rem;
    }

    .zoomModal-addToList {
      font-size: 1.8rem;
      // display: inherit;
    }

    .zoomModal_img--container {
      margin: 0.9vw auto;
      min-height: 35rem;
      max-height: none;
      transform: translateY(-10%);
      width: 65vw;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  

  @media screen and (max-width: 1400px) {
    .zoomModal_img--container {
      width: 75vw;
    }
  }

  @media screen and (max-width: 1100px) {
    width: 95%;

    .zoomModal_img--container {
      width: 90vw;
    }
  }

  @media screen and (max-height: 950px) {
    transform: translate(-50%, 0);
    top: 0;
    overflow-y: auto;
    height: 100%;
    .zoomModal_img--container {
      position: relative;
      margin: 0.9vw auto;
      min-height: 35vw;
    }
  }

  @media screen and (max-width: 560px) {
    display: none;
  }
}

.added-btn {
  font-weight: 800;
}

.mobileAddItem {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2.8vmax;
  transform: translateY(0.125rem);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &--container {
    z-index: 299;
  }

  svg {
    width: 2.85rem;
    height: auto;
    position: absolute;
    transition: 0.13s opacity ease-out;
    z-index: 500;
    right: 1.5rem;
  }

  &__1 {
    opacity: 1;
  }

  &__2 {
    opacity: 0;
  }

  &:hover {
    .mobileAddItem__1 {
      opacity: 0;
    }

    .mobileAddItem__2 {
      opacity: 1;
    }
  }

  @media screen and (min-width: 560px) {
    display: none;
  }
}

.center-btn{display: inherit}



#multiModal {
  overflow-y: visible;
  max-height: 100vh;
  z-index: 800;
  min-height: auto;
  
  .zoomModal_img--container {
    margin: 0.9vw auto 2vw;
    min-height: 35rem;
    max-height: none;
    // transform: translateY(-10%);
    width: 65vw;
    height: 72%;
    max-width: 100%;
  
    img {
      max-width: 100%;
      height: auto;
    }

  }

}

#singleModal {
  overflow-y: visible;
  max-height: 100vh;
  z-index: 800;
  min-height: auto;
  
  .zoomModal_img--container {
    margin: 0.9vw auto 1.5vw;
    min-height: 35rem;
    max-height: none;
    // transform: translateY(-10%);
    width: 65vw;
    height: 70%;
    max-width: 100%;
  
    img {
      max-width: 100%;
      height: auto;
    }

  }

}

.showcase_grid #multiModal .zoomModal-addToList{
  margin: 1vw auto 0;
}
#singleModal{max-height: 100vh; height: 100vh;}


@media screen and (max-width: 750px) {
  #multiModal .zoomModal_img--container{height: calc(100% - 236px);width: 90vw;}
  .showcase_grid .zoomModal-addToList{width: 40%;}
  
}

.porduct-code{
  position: absolute;
  z-index: 100;
  bottom: 2rem;
  left: 4rem;
  font-size: 1.3rem;
  color: #aaaaaa;
  font-weight: normal;
}
.customize_text{
  position: absolute;
  bottom: 7%;
  width: 300px;
  text-align: center;
  right: 3%;
  color: #7b7b7b;
  font-weight: 400;
}
.customize_text span{
  color: #000;
    font-size: 24px;
    margin-bottom: 8px;
    display: block;
    font-weight: 700;
}
.customize_text p{
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  text-transform: none;
}


@media screen and (max-width: 800px) {
  .porduct-code{
    bottom: 2rem;
    left: 2rem;
  }
}