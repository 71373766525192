.Nav {
  position: relative;
  background-color: $color-black;
  display: flex;
  justify-content: center;
  height: 11rem;
  width: 100%;
  z-index: 300;
  transition: height .13s ease-in;

  // @media screen and (max-width: 1500px) {
  //   height: 8rem;
  // }

  @media screen and (max-width: 750px) {
    min-height: 50px;
  }

  @media screen and (max-width: 560px) {
    min-height: 75px;
  }

  @media screen and (min-width: 1025px) {
    #menuIcon,
    #closeIcon {
      display: none;
    }
  }

  &_list {
    max-width: 80%;
    width: 60%;
    font-size: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1500px) {
      font-size: 1.6rem;
    }

    @media screen and (max-width: 1025px) {
      max-width: 100%;
      position: fixed;
      z-index: 870;
      flex-direction: column;
      background-color: $color-black;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      font-size: 24px;
      opacity: 1;
      pointer-events: painted;
      transition: 0.2s opacity 0.15s ease-in-out;
      &-close {
        background-color: #111111;
        position: fixed;
        left: 0;
        bottom: 0vh;
        transform: translate(0, 0%);
        z-index: 1000;
        width: 8rem;
        height: 8rem;
        cursor: pointer;
        transition: 0.25s transform 0.15s ease-out;
        svg {
          margin: 20px;
          g line {
            stroke: #fff;
            transition: 0.3s all;
          }
        }

        &--hidden {
          transform: translate(-100%, 0%);
        }
      }

      &-menu {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(27.5%, -50%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.25s transform 0.15s ease-out;
        cursor: pointer;
        svg {
          width: 40px;
          g line {
            &:nth-child(1) {
              transform: translate(0, 25%);
            }

            &:nth-child(2) {
              transform: translate(0, 50%);
            }

            &:nth-child(3) {
              transform: translate(0, 75%);
            }
          }
        }
        p {
          color: #fff;
          font-size: 2.8rem;
          margin-left: 2rem;
        }

        &--hidden {
          transform: translate(-100%, -50%);
        }
      }
      &--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    &_item {
      position: relative;
      color: #fff;
      list-style: none;
      cursor: pointer;
      padding: 2.2rem 1.3rem 2rem;
      transform: translateY(0);
      transition: 0.1s transform;
      margin: 0 1.3vw;

      &::before {
        content: "";
        position: absolute;
        bottom: .7rem;
        left: 0;
        width: 0%;
        height: 0.3rem;
        background-color: #ffffff;
        transform: translateY(0rem);
        transition: 0.23s width ease-in, 0.1s transform;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: .7rem;
        right: 0;
        width: 0%;
        height: 0.3rem;
        transform: translateY(0rem);
        transition: 0.23s width ease-in, 0.1s transform;
      }

      &:hover {
        &::before { width: 100%; }
      }

      &--active {
        &::before {
          width: 0%;
        }

        &::after {
          width: 100%;
        }
      }
    }
  }

  &_Wishlist-check {
    position: absolute;
    top: 50%;
    right: 5vmax;
    width: 3.5rem;
    cursor: pointer;
    &_count {
      position: absolute;
      top: 0;
      right: 0;
      color: $color-black;
      font-size: 1.7rem;
      z-index: 20;
      color: whitesmoke;
      text-align: center;
      transform: translate(60%, -45%);

      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        width: 4rem; height: 4rem;
        background-color: #8e8e8e;
      }

    }

    @media screen and (max-width: 1500px) {
      width: 2.8rem;

      &_count {
        font-size: 1.5rem;
        &::before {
          width: 3rem; height: 3rem;
        }
      }
    }

    @media screen and (max-width: 560px) {
      width: 2.5rem;
      right: 3.3rem;
    }
  }
}
