.Wishlist {
  min-height: 20vmax;
  width: 100%;
  background-color: $color-black;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_subtitle {
    @include heading(1.8rem, #fff, "subtitle");
    opacity: 0.7;
    margin-top: 0.5rem;
    margin-bottom: 5rem;
  }

  &_title {
    @include heading(5.2rem, #fff, "title");
    padding: 1rem 2rem 0;
    margin-top: 8rem;
    &--icon--container {
      width: 2.2rem;
      transform: translate(2rem, -1.5rem);
    }

    @media screen and (max-width: 650px) {
      &--icon--container {
        width: 1.8rem;
        transform: translate(0.5rem, -1.5rem);
      }
    }
  }

  &_cart {
    max-width: 900px;
    width: 70%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5vmax;

    &_item {
      width: 100%;
      display: flex;
      background-color: #262626;
      height: 8rem;
      align-items: center;
      position: relative;
      margin: 1rem;

      &-name {
        color: rgba(#fff, 0.5);
        font-size: 2rem;
        margin-left: 2.5rem;
      }

      &-image {
        height: auto;
        max-width: 95%; max-height: 95%;

        &--container {
          width: 8.8%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          min-width: 7rem;
        }
      }

      &--editor {
        position: absolute;
        right: 0;
        display: flex;
        min-width: 32.5rem;
        max-width: 42.5rem;
        align-items: stretch;
        justify-content: flex-end;
        height: 100%;
        margin-right: 2rem;
        &-note,
        &-remove {
          min-width: 16%;
          cursor: pointer;

          svg {
            height: 2.5vmax;
            width: auto;
            overflow: visible;
            g > * {
              stroke: #5d5d5d;
              transition: stroke 0.3s;
              overflow: visible;

            }
          }
        }

        &-remove {
          height: 100%;
          display: flex;
          justify-content: center;
          margin-left: -0.8rem;
          svg {
            align-self: center;
            height: 30%;
          }
        }

        &-note {
          height: 100%;
          flex-grow: 2;
          display: flex;
          justify-content: flex-end;
          align-items: stretch;
          margin: 0 0.25vmax 0 0;

          :focus,
          :active {
            outline: none;
          }

          svg {
            align-self: center;
            height: 35%;
          }

          .addNote {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 1.2rem;
            transition: 0.35s width ease;

            &--active {
              width: 100%;

              label {
                opacity: 1;
                transition: 0.3s all 0.15s;
              }
              input {
                &:focus {
                  + label {
                    transform: translateY(-500%);
                    opacity: 0;
                  }
                }
              }
            }

            &--inactive {
              width: 0%;

              label {
                opacity: 0;
                transition: 0.125s all;
              }
            }

            input {
              width: 100%;
              height: 70%;
              border: none;
              border-bottom: 0.2rem rgba(#fff, 0.4) solid;
              background-color: transparent;
              font-family: "UniversCondensed";
              font-weight: 800;
              font-size: 2.1rem;
              color: #fff;
              &:focus,
              &:active {
                outline: none;
              }
            }

            label {
              position: absolute;
              margin-left: 1.2rem;
              color: #fff;
              text-transform: uppercase;
              font-family: "UniversCondensed";
              font-weight: 800;
              font-size: 2.1rem;
              pointer-events: none;
            }

            svg {
              transition: 0.14s all ease-out;
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }

    &--empty {
      width: 100%;
      height: 15vmax;
      min-height: 20rem;
      max-height: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3.2rem;
      color: #fff;

      &_icon {
        width: 3vmax;
        max-width: 5rem;
        min-width: 4rem;
      }
      &_message {
        text-align: center;
        font-size: 2.8rem;
        margin-top: 1.8rem;
      }
      &_cta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        transform: translateX(1.5rem);
        &-link {
          text-decoration: none;
          color: #fff;
          font-size: 2.6rem;
          display: inline-block;
          position: relative;
          z-index: 25;
          transition: 0.15s color ease-in;

          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.23rem;
            transform: translateY(0) scaleX(1);
            transition: 0.25s all ease-out;
          }

          &:hover {
            color: $color-black;
            &::before {
              height: 175%;
              transform: translateY(1rem) scaleX(1.5);
            }
            + svg {
              animation: repeatingYBounce 0.25s ease-in both alternate infinite;
              g line,
              g path {
                stroke: $color-black;
              }
            }
          }
        }

        svg {
          transform: rotate(-90deg) translateX(0);
          height: 2rem;
          position: relative;
          z-index: 30;

          g line,
          g path {
            stroke-width: 0.4rem;
            transition: 0.15s stroke 0.25s;
          }
        }
      }
      @media screen and (max-width: 650px) {
        &_icon {
          min-width: 0;
          width: 3.25rem;
        }

        &_message {
          font-size: 2.35rem;
          margin-top: 1.25rem;
        }

        &_cta {
          transform: translateX(2rem);

          &-link {
            font-size: 2.25rem;

            &:hover {
              &::before {
                transform: translateY(0.75rem) scaleX(1.5);
              }
            }
          }

          svg {
            height: 1.65rem;
          }
        }
      }

      @keyframes repeatingYBounce {
        0% {
          transform: rotate(-90deg) translateX(0);
        }

        100% {
          transform: rotate(-90deg) translateX(0.2rem);
        }
      }
    }
  }
}

.item_remove {
  &-warning {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;

    #trashSVG {
      width: 2rem;
      margin-left: 2.5rem;
      svg {
        left: 0;
      }
    }
  }

  &-text {
    color: rgba(#fff, 0.95);
    font-size: 2.3rem;
    margin-left: 2.5rem;
    margin-right: 1.25rem;
  }

  &-btn {
    background-color: transparent;
    height: 100%;
    padding: 0rem 4.75rem;
    margin-right: 1.5rem;
    border: none;
    font-size: 1.5rem;
    position: relative;
    overflow: hidden;
    z-index: 15;
    cursor: pointer;
    transition: 0.225s all ease-in;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      background-color: #eaeaea;
      transition: 0.125s all ease-out;
    }

    &--container {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 2rem 0 2rem 0;
      margin-left: auto;

      #cancelRemove {
        border: 0.2rem solid #eaeaea;
        color: #eaeaea;

        &::before {
          height: 0%;
          z-index: -1;
          bottom: 0;
        }

        &:hover {
          color: $color-black;

          &::before {
            height: 100%;
          }
        }
      }

      #remove {
        color: $color-black;
        border: 0.2rem solid #eaeaea;

        &::before {
          height: 100%;
          z-index: -1;
          top: 0;
        }

        &:hover {
          color: #eaeaea;

          &::before {
            height: 0%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .Wishlist_cart {
    padding-bottom: 15rem;
    &_item {
      position: relative;
      width: 70vw;

      &--editor {
        margin-right: 0rem;
        min-width: none;
        width: 25rem;
        max-width: 30rem;
        &-note,
        &-remove {
          min-width: 25%;
          svg {
            height: 2.04rem;
          }
        }
        &-note {
          &--active {
            position: absolute;
            z-index: 500;
            background-color: #262626;
            width: 70vw;
            padding: 1.5rem 1.75rem 1.6rem 4rem;
            top: 100%;
            justify-content: space-between;
            height: 10rem;
            .addNote {
              width: 87.5%;

              &_label,
              input[name="wishListNote"] {
                font-size: 1.75rem !important;
              }

              &_label {
                margin-left: 0.6rem !important;
              }
            }

            svg {
              height: 2.5rem;
            }
          }
        }
      }

      .item_remove {
        &-text {
          font-size: 1.7rem;
          margin-left: 1.75rem;
        }

        &-btn {
          padding: 0rem 2.6rem;
          height: 90%;
          font-size: 1.2rem;
        }
        &-warning {
          #trashSVG {
            width: 2.4rem;
            margin-left: 1.75rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Wishlist_cart {
    &_item {
      width: 80vw;
    }
  }

  .item_remove {
    &-text {
      display: none;
    }
    &-btn {
      width: 50%;
      &--container {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .Wishlist_cart {
    width: 95%;
    padding-bottom: 12.5rem;
    &_item {
      position: relative;
      width: 95vw;
      &-name {
        margin-left: 1rem;
        font-size: 1.8rem;
        max-width: 45%;
      }
      &--editor-note {
        &--active {
          width: 95vw;
          height: 10rem;
        }
      }
    }
  }

  .item_remove {
    &-btn {
      &--container {
        width: calc(100% - 2rem);
        #remove {
          width: 62.5%;
        }
        #cancelRemove {
          width: 37.5%;
        }
      }
    }

    &-warning {
      #trashSVG {
        display: none;
      }
    }
  }
}
