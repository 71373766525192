.Wishlist_Send {
    min-height: 40vmax;
    width: 100%;
    background: linear-gradient(to bottom left, #e6e6e6, #e0e0e0);
    display: flex;
    flex-direction: column;
    align-items: center;

    &_subtitle {
      @include heading(1.8rem, $color-black, "subtitle");
      opacity: 0.7;
      padding-bottom: 1.5rem;
      margin-top: .5rem;
    }

    &_title {
      @include heading(5.2rem, $color-black, "title");
      padding: 1rem 2rem 0;
      margin-top: 8rem;
      &--icon--container {
        width: 3.2rem;
        transform: translate(1.75rem, -2.2rem);

        svg g > * {
          stroke: $color-black;
        }
      }

      @media screen and (max-width: 650px) {
        &--icon--container {
          width: 2.4rem;
          transform: translate(.25rem, -1.7rem);
        }
      }
      @media screen and (max-width: 400px) {
        &--icon--container {
          transform: translate(0rem, -1.7rem);
        }
      }
    }

    &-form {
      margin-top: 5.5rem;
      width: 60%;
      max-width: 700px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &_input {
        position: relative;
        height: 100%;
        width: 100%;
        padding-left: 5%;
        padding-right: 2.5%;
        font-size: 1.8rem;
        border: none;
        font-weight: 400;
        background-color: rgba(255, 255, 255, 0.8);
        transition: 0.3s background-color ease-out;
        font-family: 'UniversCondensed';
        font-weight: 800;
        color: rgb(0, 0, 0);

        &:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 1);

          + label {
            transform: translate(0, -500%);
          }
        }

        &--textarea {
          line-height: 2.2rem;
          resize: vertical;
          max-height: 50rem;
          min-height: 17.5rem;
          padding-top: 2rem;

          &::placeholder {
            color: rgba(0, 0, 0, 0.35);
            font-family: "UniversCondensed";
            font-weight: 700;
            transition: color 0.15s linear;
          }

          &:focus,
          &:active {
            &::placeholder {
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        &--container {
          height: 7rem;
          position: relative;
          overflow: hidden;
          box-shadow: 0 0.2vmax 0.75rem rgba(0, 0, 0, .2);

          svg {
            position: absolute;
            height: 100%;
            padding: 2.2rem;
            width: auto;
            right: 0; top: 0;
            transition: .22s all ease-out;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0.35vmax;
            pointer-events: none;
            transition: 0.25s width linear;
          }

          &--fullwidth {
            width: 100%;
            margin: 0.5rem 0;
          }
          &--halfwidth {
            width: calc(50% - 0.5rem);
            label {
              left: 10%;
            }
            input {
              padding-left: 10%;
            }
            @media screen and (max-width: 725px) {
              width: 100%;

              label {
                left: 5%;
              }
              input {
                padding-left: 5%;
              }
            }
          }

          &--textarea {
            height: 100%;
            max-height: 30rem;
            label {
              top: 25%;
            }

            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 0vmax;
              pointer-events: none;
              background-color: transparent !important;
              border: none;
            }
          }
        }

        &--label {
          position: absolute;
          top: 50%;
          left: 5%;
          font-size: 1.8rem;
          transform: translate(0, -50%);
          font-family: "UniversCondensed";
          font-weight: 700;
          pointer-events: none;
          color: rgba(0, 0, 0, 0.35);
          transition: 0.45s transform ease-out;
        }
      }

      &_submit {

        @include submit(6rem, true);
        width: 90%;
        max-width: 40rem;
        margin: 4rem 0 11rem;

        @media screen and (max-width: 650px) {
          width: 85%;
        }
        @media screen and (max-width: 550px) {
          width: 95%;
        }
      }

      @media screen and (max-width: 1150px) {
        &_input--container:not(.Wishlist_Send-form_input--container--textarea) {
          min-height: 4.5rem;
          height: 6rem;
        }
        &_input--textarea {
          min-height: 15rem;
          height: 12.5vw;
        }
      }
      @media screen and (max-width: 660px) {
        width: calc(100% - 2rem);
        padding-left: 1rem; padding-right: 1rem;
      }
    }
  }

.Wishlist_Sent {
  display: flex;
  background: linear-gradient(to bottom left, #e6e6e6, #e0e0e0);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: appear .4s ease-in-out 1 forwards;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }

  &-check {
    width: 11rem;
    margin-bottom: 6rem; margin-top: 21.6rem;
    transform: translateY(-300%);
    opacity: 0;
    animation: moveDown .3s ease-out .1s 1 forwards;

    @keyframes moveDown {
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &-thanks {
    font-size: 5.2rem;
    text-align: center;
    line-height: 1.1;
    padding: 0 1.3rem;
    width: 100%;
    color: #212121;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 25.4rem;
    margin-top: .45rem;
    color: #7E7E7E;
  }

  @media screen and (max-width: 850px) {
    &-check {
      width: 9.25rem;
      margin-bottom: 4rem; margin-top: 16.6rem;
    }
    &-thanks {
      font-size: 4.1rem;
    }
  }
}

  .email-submitted {
    color: #000;
    transition: 0.125s box-shadow ease-in, 0.04s color 0.04s ease-in-out;
    &::before {
      height: 0%;
    }

    span[data-type="arrow"] {
      transform: translate(1.5rem, -50%);
    }

    #arrowLine,
    #arrowPath {
      stroke: #000 !important;
    }
  }